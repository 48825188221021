import React, { useRef } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import "./horizontalScroll.css";
import Card from '../ImageContainer/Card'; // Ensure Card is properly imported
import image1 from "../../assets/servicePngs/webDes&Dev.png";
import image2 from "../../assets/servicePngs/seo.png";
import image3 from "../../assets/servicePngs/GraphicDesign.png";
import image4 from "../../assets/servicePngs/AppDev.png";
import image5 from "../../assets/servicePngs/SMSMarketing.png";

// Create an array of images to map over
const imageList = [
  { url: image1, title: "Web Design & Development", id: 1 },
  { url: image2, title: "SEO", id: 2 },
  { url: image3, title: "Graphic Design", id: 3 },
  { url: image4, title: "App Development", id: 4 },
  { url: image5, title: "SMS Marketing", id: 5 }
];

export const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  // Adjust the transform based on scroll for desktop and mobile
  const x = useTransform(scrollYProgress, [0, 1], ["5%", "-65%"]);
  const mobileX = useTransform(scrollYProgress, [0, 1], ["0%", "-90%"]); // Scroll horizontally for mobile

  return (
    <section ref={targetRef} className="relative h-[300vh] bg-[#000]">
      {/* Desktop and larger devices */}
      <div className="sticky top-0 hidden md:flex h-screen items-center overflow-hidden">
        <motion.div style={{ x }} className="flex gap-7">
          {imageList.map((card) => (
            <Card card={card} key={card.id} />
          ))}
        </motion.div>
      </div>

      <div className="sticky top-0 flex md:hidden h-screen items-center overflow-hidden">

        <motion.div
          style={{ x: mobileX }} 
          className="flex gap-3 mt-[5rem]" 
        >
          {imageList.map((card) => (
            <Card card={card} key={card.id} />
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default HorizontalScrollCarousel;
