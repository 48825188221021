import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";

const ContactUs = () => {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("contact-section");
      const sectionTop = section.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop <= windowHeight / 1 && sectionTop >= -windowHeight / 2) {
        setIsInView(true);
      } else {
        setIsInView(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      id="contact-section"
      className="h-screen flex justify-center items-center relative overflow-hidden"
    >
      {/* Black Overlay */}
      <motion.div
        className="absolute inset-0 w-screen h-screen bg-black"
        initial={{ scale: 0, x: "100%", y: "100%" }}
        animate={{ scale: isInView ? 1 : 0, x: 0, y: 0 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
      />

      {/* Content */}
      <div className="relative flex flex-col justify-center items-center text-white z-10">
        <div className="flex flex-col md:flex-row md:space-x-[80px] space-y-4 md:space-y-0">
          <a
            href="https://www.instagram.com/kuntech7/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[120px] md:text-[220px] hover:text-gray-400"
          >
            <FaInstagram />
          </a>
          <a
            href="mailto:kuntech07@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[120px] md:text-[220px] hover:text-gray-400"
          >
            <BiLogoGmail />
          </a>
          <a
            href="https://x.com/Kuntech7"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[120px] md:text-[220px] hover:text-gray-400"
          >
            <FaXTwitter />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
