import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import videoFile from '../assets/visionaryCyber.mp4';

const AboutUs = () => {
  const [currentSection, setCurrentSection] = useState('vision');
  const videoRef = useRef(null);

  // Automatically toggle between sections (vision/mission)
  useEffect(() => {
    const sectionInterval = setInterval(() => {
      setCurrentSection((prev) => (prev === 'vision' ? 'mission' : 'vision'));
    }, 10000); // 5 seconds for each section

    return () => clearInterval(sectionInterval);
  }, []);

  return (
    <div className="relative overflow-hidden">
      {/* Background video */}
      <video
        ref={videoRef}
        className="absolute inset-0 w-full h-full object-cover z-[-1]"
        autoPlay
        muted
        loop
      >
        <source src={videoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay content with Vision and Mission */}
      <div className="relative z-10 h-screen flex flex-col justify-center items-center text-center text-white px-4">
    
        {currentSection === 'vision' && (
          <motion.div
            key="vision"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 1, ease: 'easeOut' }}
            className="mb-16"
          >
            <h2 className="text-5xl font-bold mb-12 font-jua">Our Vision</h2>
            <p className="text-2xl max-w-3xl mx-auto font-jua">
              The vision focuses on transforming how brands engage with audiences, continually evolving with the
              digital landscape while creating impactful strategies that shape the future of marketing. Through
              creativity, strategic insights, and cutting-edge technology, businesses can achieve their marketing
              goals efficiently and effectively.
            </p>
          </motion.div>
        )}

        {currentSection === 'mission' && (
          <motion.div
            key="mission"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 1, ease: 'easeOut' }}
            className="mb-16"
          >
            <h2 className="text-5xl font-bold mb-12 font-jua">Our Mission</h2>
            <p className="text-2xl max-w-3xl mx-auto font-jua">
              The mission is to empower businesses with innovative, data-driven digital marketing solutions that
              drive growth, build lasting customer relationships, and deliver measurable results.
            </p>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default AboutUs;
