// BlogCard.js
import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ id, title, date, summary, route, image, altText }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-6 w-full max-w-md">
      {/* Render the image */}
      <img
        src={image}
        alt={altText}
        className="w-full h-48 object-cover rounded-t-lg mb-4" // Styles for the image
      />
      <div className="text-[black] text-xs">{date}</div>
      <h2 className="text-xl font-semibold text-[black] mt-2 mb-4">{title}</h2>
      <p className="text-[black] mb-4">{summary}</p>
      <Link
        to={route}
        className="text-blue-500 hover:text-blue-700 font-medium"
      >
        Read More
      </Link>
    </div>
  );
};

export default BlogCard;
