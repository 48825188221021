// Navbar.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAnimate, stagger } from "framer-motion";
import Logo from "../assets/Logo.png";

function useMenuAnimation(isOpen) {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const menuAnimations = isOpen
      ? [
          ["nav", { transform: "translateX(0%)" }, { duration: 0.6 }],
          [
            "li",
            { transform: "scale(1)", opacity: 1, filter: "blur(0px)" },
            { delay: stagger(0.05) },
          ],
        ]
      : [
          [
            "li",
            { transform: "scale(0.5)", opacity: 0, filter: "blur(10px)" },
            { delay: stagger(0.05, { from: "last" }) },
          ],
          ["nav", { transform: "translateX(-100%)" }],
        ];

    document.body.style.overflow = isOpen ? "hidden" : "auto";

    animate(menuAnimations);
  }, [isOpen, animate]);

  return scope;
}

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const scope = useMenuAnimation(isOpen);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setShowNavbar(currentScrollY < lastScrollY || currentScrollY === 0);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div className="relative">
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={() => setIsOpen(false)}
        />
      )}

      <nav
        className={`fixed top-0 left-0 w-full bg-transparent text-white z-50 transition-transform duration-500 ease-in-out ${
          showNavbar ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <div className="container mx-auto flex justify-between items-center py-2">
          <div className="ml-4">
            <button
              className="bg-black border rounded-xl border-white text-white py-2 px-4 font-jua hover:scale-110 transition-all duration-300 ease-in-out"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? "X" : "Get Menu"}
            </button>
          </div>

          <div className="flex justify-center">
            <Link to="/">
              <img src={Logo} alt="Logo" className="h-[100px] w-auto" />
            </Link>
          </div>

          <div className="mr-4">
            <a
              href="https://forms.gle/BCF7nKQx28TmzWCT8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="bg-black border rounded-xl border-white text-white py-2 px-4 font-jua hover:scale-110 transition">
                Get in Touch
              </button>
            </a>
          </div>
        </div>
      </nav>

      <div ref={scope}>
        <nav className="fixed top-0 left-0 w-[300px] h-full bg-black text-white z-40 transform -translate-x-full">
          <ul className="space-y-6 p-8 fixed top-[10vh] text-3xl">
            <li className="opacity-0 transform scale-50 font-jua">
              <Link to="/blogs" onClick={() => setIsOpen(false)}>
                Blogs
              </Link>
            </li>
            <li className="opacity-0 transform scale-50 font-jua">
              <Link to="/products" onClick={() => setIsOpen(false)}>
                Products
              </Link>
            </li>
            <li className="opacity-0 transform scale-50 font-jua">
            <a
              href="https://forms.gle/BCF7nKQx28TmzWCT8"
              target="_blank"
              rel="noopener noreferrer"
            >
                Contact Us
             </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
