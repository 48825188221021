// ComingSoon.jsx
import React from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const ComingSoon = () => {
  return (
    <>
      <Helmet>
        <title> ComingSoon</title>
      </Helmet>
      <div className="flex flex-col items-center justify-center min-h-screen bg-white">
        <motion.h1
          className="text-6xl font-jua text-black mb-8"
          initial={{ y: -70, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 2 }}
        >
          Coming Soon
        </motion.h1>

        <motion.p
          className="text-lg font-jua text-gray-600 mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 5 }}
        >
          Good things are worth the wait !
        </motion.p>
        <motion.button
          className="px-6 py-2 text-lg font-jua text-white bg-black rounded hover:bg-gray-700 transition"
          onClick={() => (window.location.href = "/")}
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Go to Homepage
        </motion.button>
      </div>
    </>
  );
};

export default ComingSoon;
