import React from 'react';
import { Helmet } from 'react-helmet-async';
import NavBar from '../Components/NavBar';
import BlogCard from "../Components/BlogCard";

const blogArticles = [
  {
    id: 2,
    title: "How Important Is SEO for Ranking Your Website on Google?",
    date: "Nov 07, 2024",
    summary: "Discover why SEO is essential for ranking your website on Google, covering on-page, off-page, and technical SEO strategies that boost visibility.",
    route: "/blogs/Importance-of-SEO-for-Google-Ranking",
    image: "/BlogImage/seo.jpg",
  },
  {
    id: 1,
    title: "Why Every Business Needs a Digital Strategy in 2024?",
    date: "Oct 28, 2024",
    summary: "A digital strategy is no longer optional—it’s a necessity for businesses that want to succeed in 2024 and beyond.",
    route: "/blogs/Business-Needs-a-Digital-Strategy",
    image: "https://kuntech.in/BlogImage/business-strategy-corporation-enterprise-startup-concept.jpg",
  },
 
  
  // Add more blog articles here as needed
  ];

    const blogStructuredData = {
      "@context": "http://schema.org",
      "@type": "Blog",
      "mainEntityOfPage": "https://kuntech.in/blogs",
      "headline": "Blogs | Insights for 2024 Business Strategies",
      "description": "Explore our latest blogs on digital strategies and business insights for 2024.",
      "author": {
        "@type": "Organization",
        "name": "Kuntech"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Kuntech",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kuntech.in/logo.png"
        }
      },
      "image": "https://kuntech.in/BlogImage/technology-communication-icons-symbols-concept.jpg",
      "blogPost": blogArticles.map(article => ({
        "@type": "BlogPosting",
        "headline": article.title,
        "datePublished": article.date,
        "url": `https://kuntech.in${article.route}`,
        "description": article.summary
      }))
    };

const Blogs = () => {
  return (
    <>
      <Helmet>
        <title>Blogs | Insights for 2024 Business Strategies</title>
        <link rel="manifest" href="https://kuntech.in/BlogImage/technology-communication-icons-symbols-concept.jpg" />
        <meta name="description" content="Explore our latest blogs on digital strategies, tips for business success in 2024, and insights into the evolving digital landscape." />
        <meta property="og:title" content="Blogs | Insights for 2024 Business Strategies" />
        <meta property="og:description" content="Discover various insights into why every business needs a digital strategy in 2024. Stay informed with our blog articles on digital transformation and more." />
        <meta property="og:image" content="https://kuntech.in/BlogImage/technology-communication-icons-symbols-concept.jpg" />
        <meta property="og:url" content="https://kuntech.in/blogs" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Blogs | Insights for 2024 Business Strategies" />
        <meta name="twitter:description" content="Stay ahead with our blog articles on digital strategies and business tips for 2024 and beyond." />
        <meta name="twitter:image" content="https://kuntech.in/BlogImage/technology-communication-icons-symbols-concept.jpg" />

        <script type="application/ld+json">
          {JSON.stringify(blogStructuredData)}
        </script>
      </Helmet>

      <NavBar />
      <div className="container mx-auto py-8 px-4 relative top-[100px] font-jua">
        <h1 className="text-2xl md:text-3xl font-bold text-[black] mb-8">Blog Articles</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogArticles.map((article) => (
            <BlogCard
              key={article.id}
              id={article.id}
              title={article.title}
              date={article.date}
              summary={article.summary}
              route={article.route}
              image={article.image}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Blogs;
