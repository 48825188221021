import React from "react";
import { FaFigma, FaMailchimp, FaAws } from "react-icons/fa6";
import { SiHostinger, SiReact, SiGithub } from "react-icons/si";
import "../assets/css/cube.css";
import Cube from "../Components/Cube";

const OurPatners = () => {
  return (
    <div className="min-h-screen bg-[#000] flex flex-col md:flex-row">
      {/* Left section with partners */}
      <div className="w-full md:w-1/2">
        <h2 className="text-[#fff] text-3xl font-jua p-4 ml-[30px]">
          Our Partners
        </h2>
        <div className="grid grid-cols-2 p-3 ml-[30px] opacity-[0.7]">
  <a
    href="https://www.hostinger.com"
    target="_blank"
    rel="noopener noreferrer"
  >
    <SiHostinger
      className="my-7 text-[90px] sm:text-[120px] text-white"
      alt="Hostinger"
    />
  </a>
  <a
    href="https://aws.amazon.com"
    target="_blank"
    rel="noopener noreferrer"
  >
    <FaAws className="my-7 text-[90px] sm:text-[120px] text-white" alt="AWS" />
  </a>
  <a
    href="https://mailchimp.com"
    target="_blank"
    rel="noopener noreferrer"
  >
    <FaMailchimp
      className="my-7 text-[90px] sm:text-[120px] text-white"
      alt="Mailchimp"
    />
  </a>
  <a
    href="https://github.com"
    target="_blank"
    rel="noopener noreferrer"
  >
    <SiGithub
      className="my-7 text-[90px] sm:text-[120px] text-white"
      alt="GitHub"
    />
  </a>
  <a
    href="https://reactjs.org"
    target="_blank"
    rel="noopener noreferrer"
  >
    <SiReact
      className="my-7 text-[90px] sm:text-[120px] text-white"
      alt="React.js"
    />
  </a>
  <a
    href="https://www.figma.com"
    target="_blank"
    rel="noopener noreferrer"
  >
    <FaFigma
      className="my-7 text-[90px] sm:text-[120px] text-white"
      alt="Figma"
    />
  </a>
</div>

      </div>

      {/* Right section with rotating cube */}
      <div className="flex md:justify-center md:items-center w-1/2">
        <Cube />
      </div>
    </div>
  );
};

export default OurPatners;
