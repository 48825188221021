// src/Components/AnalyticsTracker.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function AnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-RW2JDK9HRP', { page_path: location.pathname });
    }
  }, [location]);

  return null; // This component doesn't render anything
}

export default AnalyticsTracker;
