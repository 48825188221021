import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const quotes = [
  { text: "Creativity is intelligence having fun.", author: "Albert Einstein" },
  { text: "Creativity is seeing what others see and thinking what no one else has ever.", author: "Albert Einstein" },
  { text: "Innovation distinguishes between a leader and a follower.", author: "Steve Jobs" },
  { text: "Amazing products come from amazing minds.", author: "" },
];

const TextCarousel = () => {
  const [index, setIndex] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % quotes.length);
    }, 12000); 
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="h-screen flex items-center justify-center overflow-x-hidden">
      <div className="w-screen max-w-2xl leading-relaxed text-center p-7 rounded-lg relative">
        {/* Carousel for quote text with fade-in and typewriter effect */}
        <AnimatePresence mode="wait">
          <motion.div
            key={index}
            initial={{ opacity: 0 }} // Fade in
            animate={{ opacity: 1 }}   // Fully visible
            exit={{ opacity: 0 }}      // Fade out
            transition={{ duration: 1 }} // Smooth transition
            className="text-black text-2xl lg:text-4xl font-bold font-jua"
          >
            <TypewriterEffect text={quotes[index].text} />
          </motion.div>
        </AnimatePresence>

        {/* Gap between the quote and the author */}
        <div className="mt-4"></div>

        {/* Author text */}
        <AnimatePresence mode="wait">
          <motion.div
            key={index}
            initial={{ opacity: 0 }} // Fade in
            animate={{ opacity: 1 }}   // Fully visible
            exit={{ opacity: 0 }}      // Fade out
            transition={{ duration: 3 }} // Smooth transition
            className="text-gray-400 text-sm lg:text-lg font-jomhuria"
          >
            - {quotes[index].author }
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

// Typewriter effect component
const TypewriterEffect = ({ text }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      setDisplayedText(text.slice(0, i));
      i++;
      if (i > text.length) clearInterval(interval);
    }, 100); // Speed of typing effect

    return () => clearInterval(interval);
  }, [text]);

  return <span>{displayedText}</span>;
};

export default TextCarousel;
