import React from "react";
import "./imagecontainer.css";

const Card = ({ card }) => {
  return (
    <div
      key={card.id}
      className="group relative flex flex-col items-center h-[350px] w-[350px] sm:h-[450px] sm:w-[450px] overflow-hidden"
    >
      {/* Image with proper sizing */}
      <img
        src={card.url}
        alt={card.title}
        className="h-[80%] w-full object-contain bg-[#1e1e1e]"
      />

      {/* Title below image with consistent gap */}
      <div className="mt-4 text-center">
        <p className="bg-gradient-to-br from-white/20 to-white/0 px-6 py-3 text-lg sm:text-2xl font-black uppercase text-white backdrop-blur-lg font-jua">
          {card.title}
        </p>
      </div>
    </div>
  );
};

export default Card;
