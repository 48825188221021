// src/App.js
import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import HomePage from "./Pages/HomePage";
import Blogs from "./Pages/Blog";
import Blog1 from "./Pages/BlogPosts/Blog1";
import Blog2 from "./Pages/BlogPosts/Blog2";
import Products from "./Pages/Products";
import Contact from "./Pages/ContactUs";
import AnalyticsTracker from "./Components/AnalyticsTracker";

// ScrollToTop component to handle scrolling
const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Scroll to top on route change
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

function App() {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <AnalyticsTracker />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/Business-Needs-a-Digital-Strategy" element={<Blog1 />} />
          <Route path="/blogs/Importance-of-SEO-for-Google-Ranking" element={<Blog2 />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
