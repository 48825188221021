import React from 'react';
import { Helmet } from 'react-helmet-async'; // Import Helmet for SEO management
import NavBar from '../Components/NavBar';
import TextCarousel from '../Components/TextCarousel';
import OurPatners from '../Components/OurPatners';
import HorizontalScrollComponent from '../Components/HorizontalScroll/HorizontalScrollComponent';
import AboutUs from '../Components/AboutUs';
import ContactUs from '../Components/ContactUs';
import thumbnail from "../assets/thumbnail.png";

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>Kuntech | Digital Advertising Agency</title>
        <link rel="manifest" href="%PUBLIC_URL%/logo.png" />
        <meta
          name="description"
          content="Kuntech is a digital advertising agency specializing in web and app development, SEO, graphic design, and email marketing."
        />
        <meta
          name="keywords"
          content="Kuntech, digital advertising, web development, app development, SEO, graphic design, email marketing, tech services"
        />
        <meta name="author" content="Kuntech Agency" />
        <meta property="og:title" content="Kuntech | Digital Advertising Agency - Web & App Development, SEO, Marketing" />
        <meta property="og:description" content="Grow your business with Kuntech's expert services in web and app development, SEO, graphic design, and email marketing." />
        <meta property="og:image" content={thumbnail} />
        <meta property="og:url" content="https://kuntech.in" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Kuntech | Digital Advertising Agency" />
        <meta name="twitter:description" content="Kuntech offers expert services in web and app development, SEO, graphic design, and email marketing." />
        
        {/* Google Analytics Tag */}
        
      </Helmet>

      {/* Website Content */}
      <NavBar />
      <TextCarousel />
      <OurPatners />
      <HorizontalScrollComponent />
      <AboutUs />
      <ContactUs />
    </>
  );
};

export default HomePage;
