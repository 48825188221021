import React, { useEffect } from "react";
import "../assets/css/cube.css"; // Import specific styles for the cube

const Cube = () => {
  useEffect(() => {
    const cube = document.querySelector(".custom-cube");
    const grids = document.querySelectorAll(".custom-grid");

    // Function to apply different rotations based on screen size
    const applyRotation = () => {
      if (window.innerWidth <= 768) {
        // Mobile view
        cube.style.transform = `rotateX(15.5022deg) rotateY(167.733deg)`;
      } else {
        // Desktop view
        cube.style.transform = `rotateX(-15.8049deg) rotateY(121.23deg)`;
      }
    };

    // Apply the correct rotation on initial load
    applyRotation();

    // Listen for window resize events to reapply the rotation
    window.addEventListener("resize", applyRotation);

    grids.forEach((grid) => {
      for (let i = 0; i < 100; i++) {
        const span = document.createElement("span");
        grid.appendChild(span);
      }

      const addRandomActiveClass = () => {
        const spans = grid.querySelectorAll("span");
        const randomIndex = Math.floor(Math.random() * spans.length);
        spans[randomIndex].classList.add("active");

        const removeTime = Math.floor(Math.random() * 1000) + 500;
        setTimeout(() => {
          spans[randomIndex].classList.remove("active");
        }, removeTime);
      };

      const randomInterval = () => {
        const interval = Math.floor(Math.random() * 200) + 100;
        addRandomActiveClass();
        setTimeout(randomInterval, interval);
      };

      randomInterval();
    });

    const handleMouseMove = (e) => {
      const x = e.clientX / window.innerWidth - 0.5;
      const y = e.clientY / window.innerHeight - 0.5;
      cube.style.transform = `rotateX(${y * 360}deg) rotateY(${x * 360}deg)`;
    };

    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      const x = touch.clientX / window.innerWidth - 0.5;
      const y = touch.clientY / window.innerHeight - 0.5;
      cube.style.transform = `rotateX(${y * 360}deg) rotateY(${x * 360}deg)`;
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("touchmove", handleTouchMove);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("resize", applyRotation);
    };
  }, []);

  return (
    <div className="custom-scene z-40">
      <div className="custom-cube">
        <div className="face front">
          <div className="custom-grid"></div>
        </div>
        <div className="face back">
          <div className="custom-grid"></div>
        </div>
        <div className="face left">
          <div className="custom-grid"></div>
        </div>
        <div className="face right">
          <div className="custom-grid"></div>
        </div>
        <div className="face top">
          <div className="custom-grid"></div>
        </div>
        <div className="face bottom">
          <div className="custom-grid"></div>
        </div>
      </div>
    </div>
  );
};

export default Cube;
